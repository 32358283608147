<template>
    <div v-bind:class="wrapperClasses" v-bind:style="{ ...wrapperStyles, width: (boardSize/boardDpi) + 'px', height: (boardSize/boardDpi) + 'px' }">
        <canvas
            ref="boardCanvas"
            style="position: absolute; width: 100%; height: 100%; left: 0; top: 0"
            v-on:click="canvasClick()"
            v-bind:width="boardSize"
            v-bind:height="boardSize">
        </canvas>
        <button v-if="showHints && animationEnabled && !isPlaying" class="inline-block absolute center-transform focus:outline-none pointer-events-none text-lighter-30">Click here to play (and replay)!</button>
    </div>
</template>

<script>
    import * as helpers from '../modules/Helpers.js';

    import gsap from 'gsap';

    gsap.defaults({ overwrite: 'auto' });

    export default {
        name: "Visualizer",
        props: {
            showHints: Boolean,
            wrapperClasses: String,
            wrapperStyles: Object,

            boardSize: Number,
            boardDpi: Number,
            boardColors: Array,

            moves: Array,

            whiteBaseColor: String,
            blackBaseColor: String,
            opacityDampening: Number,
            fromOpacity: Number,
            toOpacity: Number,
            minOpacity: Number,
            opacityDampeningDelay: Number,

            animationEnabled: Boolean,
            masterDuration: Number,
            moveEasing: String,
            masterEasing: String,
            sideSwitchStagger: Number,
        },
        data() {
            return {
                ctx: null,
                boardDef: null,
                masterTimeline: null,
                masterTween: null,
                isPlaying: false
            }
        },
        computed: {
            visualProps() {
                return `${this.whiteBaseColor}|${this.blackBaseColor}|${this.opacityDampening}|${this.fromOpacity}|${this.toOpacity}|${this.minOpacity}|${this.opacityDampeningDelay}`;
            },
            animationProps() {
                return `${this.moveEasing}|${this.masterEasing}|${this.masterDuration}|${this.sideSwitchStagger}|${this.animationEnabled}`;
            }
        },
        watch: {
            moves() {
                this.destroyAnimation();
                this.createAnimation();
            },
            visualProps() {
                this.draw();
            },
            animationProps() {
                this.destroyAnimation();
                this.createAnimation();
            }
        },
        methods: {
            drawBoard() {
                const blockSize = this.boardSize / 8;

                Object.keys(this.boardDef).forEach(key => {
                    let block = this.boardDef[key];

                    this.ctx.beginPath();
                    this.ctx.rect(block.startX, block.startY, blockSize, blockSize);
                    this.ctx.fillStyle = block.color === 'light' ? this.boardColors[0] : this.boardColors[1];
                    this.ctx.fill();
                });
            },

            parseColor(color) {
                color = color.replace(/\s/g, ''); // Remove all spaces
                
                let cache;

                // Checks for 6 digit hex and converts string to integer
                if (cache = /#([\da-fA-F]{2})([\da-fA-F]{2})([\da-fA-F]{2})/.exec(color))
                    cache = [parseInt(cache[1], 16), parseInt(cache[2], 16), parseInt(cache[3], 16)];

                // Checks for 3 digit hex and converts string to integer
                else if (cache = /#([\da-fA-F])([\da-fA-F])([\da-fA-F])/.exec(color))
                    cache = [parseInt(cache[1], 16) * 17, parseInt(cache[2], 16) * 17, parseInt(cache[3], 16) * 17];

                    // Checks for rgba and converts string to
                // integer/float using unary + operator to save bytes
                else if (cache = /rgba\(([\d]+),([\d]+),([\d]+),([\d]+|[\d]*.[\d]+)\)/.exec(color))
                    cache = [+cache[1], +cache[2], +cache[3], +cache[4]];

                    // Checks for rgb and converts string to
                // integer/float using unary + operator to save bytes
                else if (cache = /rgb\(([\d]+),([\d]+),([\d]+)\)/.exec(color))
                    cache = [+cache[1], +cache[2], +cache[3]];

                // Otherwise return black
                else cache = [0, 0, 0];

                // Performs RGBA conversion by default
                isNaN(cache[3]) && (cache[3] = 1);

                // Adds or removes 4th value based on rgba support
                // Support is flipped twice to prevent erros if
                // it's not defined
                return cache.slice(0, 3);
            },
            
            getColor(moveColorCode, baseOpacity, adjustOpacity) {
                let color = moveColorCode === helpers.WHITE ? this.parseColor(this.whiteBaseColor) : this.parseColor(this.blackBaseColor);

                return 'rgba(' + color[0] + ',' + color[1] + ',' + color[2] + ',' + Math.max(this.minOpacity, (baseOpacity - (adjustOpacity * baseOpacity))) + ')';
            },

            canvasClick() {
                this.isPlaying = true;
                this.masterTween.play(0);
            },

            createAnimation() {
                this.isPlaying = false;

                this.masterTimeline = gsap.timeline({ onUpdate: this.draw })
                    .timeScale(1)
                    .pause();

                let lastColor = helpers.WHITE;

                Object.keys(this.moves).forEach((key, count) => {
                    let move = this.moves[key];
                    let tl = move.shape.getTween();
                    let subTween = gsap.to(tl, {
                        time: tl.duration(),
                        duration: tl.duration(),
                        ease: this.moveEasing,
                        delay: (lastColor !== move.color) && (move.color === helpers.WHITE) ? this.sideSwitchStagger : 0
                    });
                    this.masterTimeline.add(subTween);
                    //masterTimeline.add(subTween, (count % 2 ? '+=0' : '+=1'));

                    lastColor = move.color;
                });

                this.masterTween = gsap.to(this.masterTimeline, {
                    time: this.masterTimeline.duration(),
                    duration: this.masterDuration,
                    ease: this.masterEasing
                }).pause();

                if (!this.animationEnabled && this.masterTween !== null) {
                    this.masterTween.progress(1).pause();
                }
            },

            draw() {
                this.ctx.clearRect(0, 0, this.boardSize, this.boardSize);
                //this.drawBoard();

                let moveStartAt = 0
                let lastColor = helpers.WHITE;

                Object.keys(this.moves).forEach((key, count) => {
                    let move = this.moves[key];
                    const moveAge = (this.masterTimeline.time() - (moveStartAt + move.moveLength)) - this.opacityDampeningDelay;
                    const fromColor = this.getColor(move.color, this.fromOpacity, moveAge > 0 ? moveAge * this.opacityDampening : 0);
                    const toColor = this.getColor(move.color, this.toOpacity, moveAge > 0 ? moveAge * this.opacityDampening : 0);

                    move.shape.draw(this.ctx, fromColor, toColor);

                    moveStartAt += move.moveLength + (lastColor !== move.color && move.color === helpers.WHITE ? this.sideSwitchStagger : 0);
                    lastColor = move.color;
                });
            },

            destroyAnimation() {
                if (this.masterTimeline !== null) {
                    this.masterTimeline.kill();
                    this.masterTimeline = null;
                }

                if (this.masterTween !== null) {
                    this.masterTween.kill();
                    this.masterTween = null;
                }

                this.ctx.clearRect(0, 0, this.boardSize, this.boardSize);
            },

            toDataUrl() {
                return this.$refs.boardCanvas.toDataURL("image/png");
            }
        },
        mounted() {
            const canvas = this.$refs.boardCanvas;
            this.ctx = canvas.getContext('2d');
            this.boardDef = helpers.getBoardDef(this.boardSize);

            //this.drawBoard();
        }
    };
</script>
