import Vue from 'vue'
import App from './App.vue'

import './assets/styles/index.pcss';
import "./assets/styles/ps.pcss";
import "./assets/styles/vue-slider/custom.pcss";

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
}).$mount('#app')
