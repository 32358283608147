<template>
    <div class="fixed flex left-0 top-0 w-screen h-screen bg-darker-70 z-90 p-30" v-show="enabled">
        <div class="dialog m-auto px-50 pb-30 pt-70 -top-40 relative">
            <button class="underline focus:outline-none p-20 absolute right-0 top-0" v-on:click="onExportClose" :disabled="isExporting">Close</button>
            <div class="flex">
                <div class="w-100 pr-10">
                    <p class="label mb-5">Type</p>
                    <p class="instruction mb-5">For the purposes of this demo, only PNG export is available.</p>
                    <select v-model="exportType" class="select-box mb-30 w-240" :disabled="isExporting">
                        <option v-for="option in types" v-bind:value="option.value" v-bind:key="option.name" :disabled="option.value!=='png'">
                            {{ option.name }}
                        </option>
                    </select>

                    <div v-if="exportType !== 'png' && exportType !== 'embed'">
                        <p class="label mb-5">FPS</p>
                        <input v-model="fps" placeholder="30" class="mb-30 input-field w-130" :disabled="isExporting">
                    </div>

                    <div v-if="exportType === 'mp4'">
                        <p class="label">Quality</p>
                        <p class="instruction mb-5">Lower values result in better quality. 0 is "lossless", but will result in huge files.</p>
                        <div class="mb-30 w-130">
                            <vue-slider
                                v-model="quality"
                                :min="0"
                                :max="50"
                                :interval="1"
                                :disabled="isExporting">
                            </vue-slider>
                        </div>

                        <p class="label">Background color</p>
                        <p class="instruction mb-5">This format does not support transparency.</p>
                        <input v-model="backgroundColor" placeholder="#191419" class="mb-30 input-field w-130" :disabled="isExporting">
                    </div>

                    <div v-if="exportType === 'mp4'">
                        <p class="label mb-5">Chroma Subsampling</p>
                        <select v-model="chroma" class="select-box mb-30 w-240" :disabled="isExporting">
                            <option v-for="option in yuvTypes" v-bind:value="option.value" v-bind:key="option.name">
                                {{ option.name }}
                            </option>
                        </select>
                    </div>

                    <p class="label">Size (px)</p>
                    <p class="instruction mb-5">To avoid glitches, the size should be divisible by 8.</p>
                    <input v-model="boardSize" placeholder="1.0" class="mb-30 input-field w-130" :disabled="isExporting">
                    
                </div>
                <canvas
                    ref="exportCanvas"
                    class="bg-main-darkest flex-shrink-0 flex-grow-0"
                    v-bind:width="boardSize"
                    v-bind:height="boardSize"
                    v-bind:style="{ width: '240px', height: '240px' }">
                </canvas>
            </div>
            <div v-if="sizeWarning !== ''">
                <p v-html="sizeWarning" class="font-bold"></p>
            </div>
            <div class="mt-20 flex items-center" v-if="this.exportType !== 'embed'">
                <button class="button focus:outline-none" v-on:click="onExportClick" :disabled="isExporting">Export</button>

                <div class="status-message pl-20">
                    <p v-html="status"></p>
                </div>
            </div>
            <div class="mt-30 flex items-center" v-if="this.exportType === 'embed'">
                <textarea class="w-100 h-80 bg-transparent border-1 border-lighter-20 focus:border-lighter-20 p-10 rounded-3 resize-none" v-model="embedCode">
                    
                </textarea>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Color from 'color';
    import VueSlider from 'vue-slider-component';
    import * as helpers from '../modules/Helpers.js';
    import gsap from 'gsap';
    import axios from 'axios';
    import { stringify } from 'qs'

    gsap.defaults({ overwrite: 'auto' });

    const axiosInstance = axios.create({
        headers: {
            //'X-CSRF-Token': window.csrfToken
        },
        transformRequest: [
            function (data) {
                return stringify(data)
            },
        ],
    });

    Vue.prototype.$axios = axiosInstance;

    export default {
        name: "Exporter",
        components: { VueSlider },

        props: {
            enabled: Boolean,
            onExportClose: Function,
            pgnData: String,
            movesRange: Array,

            whiteBaseColor: String,
            blackBaseColor: String,
            opacityDampening: Number,
            fromOpacity: Number,
            toOpacity: Number,
            minOpacity: Number,
            opacityDampeningDelay: Number,

            animationEnabled: Boolean,
            masterDuration: Number,
            moveEasing: String,
            masterEasing: String,
            sideSwitchStagger: Number,
        },

        data() {
            return {
                baseServiceUrl: 'https://cct-service.vaersaagod.no/',
                baseEmbedUrl: 'https://cct-generator.vaersaagod.no/embed.html',
                boardSize: 800,
                exportType: 'png',
                fps: 25,
                chroma: 'yuv420p',
                quality: 8,
                backgroundColor: '#191419',
                ctx: null,
                boardDef: null,
                masterTimeline: null,
                masterTween: null,
                moves: [],

                isExporting: false,
                timeoutInt: 0,
                exportToken: null,
                currentFrame: 0,
                numFrames: 0,
                status: '',
                sizeWarning: '',
                embedCode: '',

                types: [
                    { value: 'png', name: 'PNG' },
                    { value: 'pngseq', name: 'PNG Sequence (ZIP)' },
                    { value: 'mp4', name: 'MP4 (h264)' },
                    { value: 'movprores', name: 'MOV (Prores 4444)' },
                    { value: 'movpng', name: 'MOV (PNG codec)' },
                    { value: 'embed', name: 'Embed' },
                ],
                yuvTypes: [
                    { value: 'yuv420p', name: '8-bit 4:2:0 (most compatible)' },
                    { value: 'yuv422p', name: '8-bit 4:2:2' },
                    { value: 'yuv444p', name: '8-bit 4:4:4' },
                    { value: 'yuv420p10le', name: '10-bit 4:2:0' },
                    { value: 'yuv422p10le', name: '10-bit 4:2:2' },
                    { value: 'yuv444p10le', name: '10-bit 4:4:4 (best quality)' },
                ]
            }
        },

        computed: {
            exportProps() {
                return `${this.boardSize}|${this.exportType}|${this.fps}`;
            }
        },

        watch: {
            boardSize() {
                clearTimeout(this.timeoutInt);

                this.timeoutInt = setTimeout(() => {
                    this.updateBoard();
                }, 500);
            },
            enabled() {
                if (this.enabled === true) {
                    this.status = '';
                    this.sizeWarning = '';
                    this.exportType = 'png';

                    this.updateBoard();
                }
            },
            exportProps() {
                if (this.exportType === 'embed') {
                    this.updateEmbedCode();
                } else {
                    this.updateSizeWarning();
                }
            },
        },

        methods: {
            getColor(moveColorCode, baseOpacity, adjustOpacity) {
                let color = moveColorCode === helpers.WHITE ? Color(this.whiteBaseColor) : Color(this.blackBaseColor);
                color = color.object();

                return 'rgba(' + color.r + ',' + color.g + ',' + color.b + ',' + Math.max(this.minOpacity, (baseOpacity - (adjustOpacity * baseOpacity))) + ')';
            },

            parseData() {
                this.boardDef = helpers.getBoardDef(this.boardSize);
                let allMoves = helpers.parseMoves(this.pgnData, this.boardDef);
                this.moves = allMoves.slice(this.movesRange[0], this.movesRange[1]);
            },

            createAnimation() {
                // create the timelines
                this.masterTimeline = gsap.timeline()
                    .timeScale(1)
                    .pause();

                let lastColor = helpers.WHITE;

                Object.keys(this.moves).forEach((key, count) => {
                    let move = this.moves[key];
                    let tl = move.shape.getTween();
                    let subTween = gsap.to(tl, {
                        time: tl.duration(),
                        duration: tl.duration(),
                        ease: this.moveEasing,
                        delay: (lastColor !== move.color) && (move.color === helpers.WHITE) ? this.sideSwitchStagger : 0
                    });
                    this.masterTimeline.add(subTween);
                    lastColor = move.color;
                });

                this.masterTween = gsap.to(this.masterTimeline, {
                    time: this.masterTimeline.duration(),
                    duration: this.masterDuration,
                    ease: this.masterEasing
                }).pause();

                this.masterTween.progress(1).pause();
            },

            draw(progress) {
                this.masterTween.progress(progress).pause();

                // draw
                this.ctx.clearRect(0, 0, this.boardSize, this.boardSize);

                if (this.exportType === 'mp4') {
                    this.ctx.beginPath();
                    this.ctx.rect(0, 0, this.boardSize, this.boardSize);
                    this.ctx.fillStyle = this.backgroundColor;
                    this.ctx.fill();
                }

                let moveStartAt = 0
                let lastColor = helpers.WHITE;

                Object.keys(this.moves).forEach((key, count) => {
                    let move = this.moves[key];
                    const moveAge = (this.masterTimeline.time() - (moveStartAt + move.moveLength)) - this.opacityDampeningDelay;
                    const fromColor = this.getColor(move.color, this.fromOpacity, moveAge > 0 ? moveAge * this.opacityDampening : 0);
                    const toColor = this.getColor(move.color, this.toOpacity, moveAge > 0 ? moveAge * this.opacityDampening : 0);

                    move.shape.draw(this.ctx, fromColor, toColor);

                    moveStartAt += move.moveLength + (lastColor !== move.color && move.color === helpers.WHITE ? this.sideSwitchStagger : 0);
                    lastColor = move.color;
                });
            },

            toDataUrl() {
                return this.$refs.exportCanvas.toDataURL("image/png");
            },

            updateBoard() {
                this.parseData();

                this.$nextTick(() => {
                    this.createAnimation();
                    this.draw(1);
                });
            },

            onExportClick() {
                if (this.exportType === 'png') {
                    this.triggerDownload(this.toDataUrl(), 'logo_' + Date.now() + '.png');
                } else {
                    if (this.boardSize <= 4000) {
                        this.exportFrames()
                    } else {
                        // todo : show error message?
                    }
                }
            },

            triggerDownload(href, filename) {
                let a = document.createElement('a');
                a.href = href;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            },

            onKeyUp(e) {
                const key = e.keyCode || e.which;

                if (this.enabled && key === 27 && !this.isExporting) {
                    this.onExportClose()
                }
            },

            exportFrames() {
                let token = null;
                this.isExporting = true;
                this.status = 'Requesting export token';

                this.$axios.get(this.baseServiceUrl + 'api/token')
                    .then(({ data }) => {
                        console.log(data);

                        if (data.success === true) {
                            this.exportToken = data.token;
                            this.sendFrames();
                        } else {
                            console.error('An error occured while initializing token.');
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });

            },

            sendFrames() {
                this.currentFrame = 0;
                this.numFrames = this.masterDuration * this.fps; // fps
                this.sendCurrentFrame();
            },

            sendCurrentFrame() {
                this.status = 'Sending frame (' + this.currentFrame + '/' + this.numFrames + ')';

                this.draw(this.currentFrame / this.numFrames);
                let data = this.toDataUrl();

                this.$axios.post(this.baseServiceUrl + 'api/store-frame/' + this.exportToken, {
                    frameIndex: this.currentFrame,
                    imageData: data
                }).then(({ data }) => {
                    this.currentFrame += 1;

                    if (this.currentFrame <= this.numFrames) {
                        this.sendCurrentFrame()
                    } else {
                        this.createExport();
                    }
                }).catch(error => {
                    console.log(error);
                });
            },

            createExport() {
                this.status = 'Encoding file. This may take a while, hang in there!';

                this.$axios.post(this.baseServiceUrl + 'api/create-export/' + this.exportToken, {
                    type: this.exportType,
                    fps: this.fps,
                    chroma: this.chroma,
                    quality: this.quality,
                    backgroundColor: this.backgroundColor,
                    duration: this.masterDuration,
                    size: this.boardSize,
                }).then(({ data }) => {
                    console.log(data);

                    if (data.success) {
                        this.downloadFile(data.file);
                    } else {
                        console.log(data);
                    }
                }).catch(error => {
                    console.log(error);
                });
            },

            downloadFile(filename) {
                this.isExporting = false;

                const url = this.baseServiceUrl + 'exported/' + filename;
                this.status = 'Downloading! If your download doesn\'t start, <a href="' + url + '" download class="underline">click here</a>';

                this.triggerDownload(url, filename);
            },

            updateSizeWarning() {
                if (this.exportType === 'png') {
                    this.sizeWarning = '';

                    if (this.boardSize > 8000) {
                        this.sizeWarning = 'The maximum canvas size is 8192px for IE, 16384px for Chrome and Safari, and 20992px for Firefox.'
                    }
                } else {
                    this.sizeWarning = '';
                    const totalFrames = this.masterDuration * this.fps;

                    if (this.boardSize > 4000 && this.exportType !== 'pngseq') {
                        this.sizeWarning = 'The maximum frame size for video export is 4000px.';
                    } else {
                        const mbPerFrame = (this.boardSize * this.boardSize) / 2000000;

                        if (totalFrames * mbPerFrame > 200) {
                            this.sizeWarning += 'You\'re about to send ~' + (totalFrames * mbPerFrame) + 'MB of data to the server. Make sure it\'s worth it!<br>';
                        }

                        if (this.exportType === 'movprores' || this.exportType === 'movpng' || (this.exportType === 'mp4' && this.quality < 20)) {
                            if (totalFrames * mbPerFrame > 300) {
                                this.sizeWarning += 'The exported file will be huge. You\'re not just playing around, are you?';
                            }
                        }
                    }

                }
            },

            updateEmbedCode() {
                const embedObject = {
                    boardSize: this.boardSize,
                    pgnData: this.pgnData,
                    movesRange: this.movesRange,
                    blackBaseColor: this.blackBaseColor,
                    whiteBaseColor: this.whiteBaseColor,
                    opacityDampening: this.opacityDampening,
                    fromOpacity: this.fromOpacity,
                    toOpacity: this.toOpacity,
                    minOpacity: this.minOpacity,
                    opacityDampeningDelay: this.opacityDampeningDelay,
                    masterDuration: this.masterDuration,
                    moveEasing: this.moveEasing,
                    masterEasing: this.masterEasing,
                    sideSwitchStagger: this.sideSwitchStagger
                };

                const encodedStr = btoa(JSON.stringify(embedObject));
                
                this.embedCode = `<div data-cct-logo-player="${encodedStr}"></div>`;
            }
        },

        mounted() {
            const canvas = this.$refs.exportCanvas;
            this.ctx = canvas.getContext('2d');

            document.addEventListener('keyup', this.onKeyUp);
        }
    };
</script>
