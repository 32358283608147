<template>
  <div id="app" class="flex flex-col h-100">
    <div id="header" class="flex flex-none h-80 border-b-3 border-darker-20 items-center justify-between px-20 py-10">
      <h1 class="text-18 font-bold">Champions Chess Tour Logo Generator</h1>
      <div class="">
        <button class="button focus:outline-none" v-on:click="onExport">Export</button>
      </div>
    </div>
    <div id="body" class="flex flex-grow-0 flex-shrink-1">
      <div class="flex-shrink-0 w-350 h-100 border-r-3 border-darker-20 overflow-hidden relative">
        <vue-custom-scrollbar id="sidebar" class="w-100 h-100 pb-40">
          <div class="border-b-1 border-darker-20 p-20">
            <p class="label mb-10">Select match</p>

            <select v-model="selectedPgnIndex" class="select-box mb-20">
              <option v-for="(option, index) in pgns" v-bind:value="index" v-bind:key="option.name">
                {{ option.name }}
              </option>
            </select>

            <span class="label mb-10 block">Or, upload a new PGN file</span>
            <div id="fileUploadWrapper" class="mb-30 border-1 border-dashed border-main-medium text-center p-10 opacity-50">
              <span class="pointer-events-none text-main-medium">Upload has been disabled.</span>
            </div>

            <p class="label mb-10 whitespace-no-wrap">Selected steps</p>
            <div class="w-100">
              <vue-slider
                  class="mb-30"
                  v-on:change="onSelectedMovesChange"
                  v-model="movesRange"
                  :min="0"
                  :max="movesRangeMax"
                  :interval="1">
              </vue-slider>
            </div>

            <div class="mb-10">
              <label for="animationEnabledCheckbox">
                <input class="mr-5" type="checkbox" id="animationEnabledCheckbox" v-model="animationEnabled">
                Enable Animation
              </label>
            </div>
          </div>

          <div class="p-20 bg-darker-20">
            <p class="font-bold opacity-50">Colors and Opacity</p>
          </div>

          <div class="border-b-1 border-darker-20 p-20">
            <p class="label mb-5">White base color</p>
            <div class="flex justify-between items-center mb-20">
              <input v-model="whiteBaseColor" placeholder="#CEB850" class="w-90 input-field text-black" v-bind:style="{ backgroundColor: whiteBaseColor }">
              <div class="mt-3">
                <button class="color-select-button bg-gold" v-on:click="whiteBaseColor = getColorFromEvent($event)"></button>
                <!--
                <button class="color-select-button bg-skilling" v-on:click="whiteBaseColor = getColorFromEvent($event)"></button>
                <button class="color-select-button bg-tourandmajors" v-on:click="whiteBaseColor = getColorFromEvent($event)"></button>
                <button class="color-select-button bg-chessmemorial" v-on:click="whiteBaseColor = getColorFromEvent($event)"></button>
                <button class="color-select-button bg-chinaopen" v-on:click="whiteBaseColor = getColorFromEvent($event)"></button>
                <button class="color-select-button bg-lindoresabbey" v-on:click="whiteBaseColor = getColorFromEvent($event)"></button>
                <button class="color-select-button bg-usopen" v-on:click="whiteBaseColor = getColorFromEvent($event)"></button>
                <button class="color-select-button bg-indianopen" v-on:click="whiteBaseColor = getColorFromEvent($event)"></button>
                <button class="color-select-button bg-legends" v-on:click="whiteBaseColor = getColorFromEvent($event)"></button>
                <button class="color-select-button bg-russianopen" v-on:click="whiteBaseColor = getColorFromEvent($event)"></button>
                -->
              </div>
            </div>

            <p class="label mb-5">Black base color</p>
            <div class="flex justify-between items-center mb-30">
              <input v-model="blackBaseColor" placeholder="#590059" class="w-90 input-field text-black" v-bind:style="{ backgroundColor: blackBaseColor }">
              <div class="mt-3">
                <!--<button class="color-select-button bg-gold" v-on:click="blackBaseColor = getColorFromEvent($event)"></button>-->
                <button class="color-select-button bg-skilling" v-on:click="blackBaseColor = getColorFromEvent($event)"></button>
                <button class="color-select-button bg-tourandmajors" v-on:click="blackBaseColor = getColorFromEvent($event)"></button>
                <!--<button class="color-select-button bg-chessmemorial" v-on:click="blackBaseColor = getColorFromEvent($event)"></button>-->
                <button class="color-select-button bg-chinaopen" v-on:click="blackBaseColor = getColorFromEvent($event)"></button>
                <button class="color-select-button bg-lindoresabbey" v-on:click="blackBaseColor = getColorFromEvent($event)"></button>
                <button class="color-select-button bg-usopen" v-on:click="blackBaseColor = getColorFromEvent($event)"></button>
                <!--<button class="color-select-button bg-indianopen" v-on:click="blackBaseColor = getColorFromEvent($event)"></button>-->
                <button class="color-select-button bg-legends" v-on:click="blackBaseColor = getColorFromEvent($event)"></button>
                <button class="color-select-button bg-russianopen" v-on:click="blackBaseColor = getColorFromEvent($event)"></button>
              </div>
            </div>

            <p class="label mb-5">From gradient opacity</p>
            <vue-slider
                class="mb-20"
                v-model="fromOpacity"
                :min="0"
                :max="1"
                :interval="0.05">
            </vue-slider>

            <p class="label mb-5">To gradient opacity</p>
            <vue-slider
                class="mb-20"
                v-model="toOpacity"
                :min="0"
                :max="1"
                :interval="0.05">
            </vue-slider>

            <p class="label mb-5">Opacity dampening</p>
            <vue-slider
                class="mb-20"
                v-model="opacityDampening"
                :min="0"
                :max="0.03"
                :interval="0.0005">
            </vue-slider>

            <p class="label mb-5">Opacity dampening delay</p>
            <vue-slider
                class="mb-20"
                v-model="opacityDampeningDelay"
                :min="0"
                :max="100"
                :interval="1">
            </vue-slider>

            <p class="label mb-5">Minimum opacity</p>
            <vue-slider
                class="mb-20"
                v-model="minOpacity"
                :min="0"
                :max="1"
                :interval="0.01">
            </vue-slider>
          </div>

          <div class="p-20 bg-darker-10" v-if="animationEnabled">
            <p class="font-bold opacity-50">Animation</p>
          </div>

          <div class="border-b-1 border-darker-20 p-20" v-if="animationEnabled">
            <p class="label mb-5">Duration (secs)</p>
            <input v-model="masterDuration" placeholder="1.0" class="mb-20 input-field">

            <p class="label mb-5">Master timeline easing function</p>
            <div class="mb-10">
              <select v-model="masterEasingFunction" class="select-box mb-10">
                <option v-for="option in easingMethods" v-bind:value="option" v-bind:key="option">
                  {{ option }}
                </option>
              </select>

              <select v-model="masterEasingType" class="select-box mb-10" v-if="masterEasingFunction !== 'none'">
                <option v-for="option in easingTypes" v-bind:value="option" v-bind:key="option">
                  {{ option }}
                </option>
              </select>
            </div>

            <p class="label mb-5">Move easing function</p>
            <div class="mb-10">
              <select v-model="moveEasingFunction" class="select-box mb-10">
                <option v-for="option in easingMethods" v-bind:value="option" v-bind:key="option">
                  {{ option }}
                </option>
              </select>

              <select v-model="moveEasingType" class="select-box mb-10" v-if="moveEasingFunction !== 'none'">
                <option v-for="option in easingTypes" v-bind:value="option" v-bind:key="option">
                  {{ option }}
                </option>
              </select>
            </div>

            <p class="label mb-5">Side switch delay</p>
            <vue-slider
                class="mb-20"
                v-model="sideSwitchStagger"
                :min="0"
                :max="20"
                :interval="1">
            </vue-slider>
          </div>
        </vue-custom-scrollbar>
      </div>

      <div id="main" class="flex w-100 flex-col flex-initial justify-center">
        <Visualizer
            ref="visualizer"
            v-bind:wrapper-classes="'relative flex my-20 mx-auto bg-main-darkest'"
            v-bind:show-hints="true"

            v-bind:board-size="Number(boardSize)"
            v-bind:board-dpi="Number(boardDpi)"
            v-bind:board-colors="boardColors"

            v-bind:white-base-color="whiteBaseColor"
            v-bind:black-base-color="blackBaseColor"
            v-bind:opacity-dampening="Number(opacityDampening)"
            v-bind:from-opacity="Number(fromOpacity)"
            v-bind:to-opacity="Number(toOpacity)"
            v-bind:min-opacity="Number(minOpacity)"
            v-bind:opacity-dampening-delay="Number(opacityDampeningDelay)"

            v-bind:animation-enabled="animationEnabled"
            v-bind:master-duration="Number(masterDuration)"
            v-bind:master-easing="masterEasing"
            v-bind:move-easing="moveEasing"
            v-bind:side-switch-stagger="Number(sideSwitchStagger)"

            v-bind:moves="selectedMoves"
        ></Visualizer>

        <div class="header text-center text-16">
          <div class v-html="matchPlayers"></div>
          <div class=" text-main-medium" v-html="matchEvent"></div>
        </div>

      </div>
    </div>

    <Exporter ref="exporter"
              v-bind:enabled="exporterEnabled"
              v-bind:on-export-close="onExportClose"
              v-bind:pgn-data="pgnData"
              v-bind:moves-range="movesRange"

              v-bind:white-base-color="whiteBaseColor"
              v-bind:black-base-color="blackBaseColor"
              v-bind:opacity-dampening="Number(opacityDampening)"
              v-bind:from-opacity="Number(fromOpacity)"
              v-bind:to-opacity="Number(toOpacity)"
              v-bind:min-opacity="Number(minOpacity)"
              v-bind:opacity-dampening-delay="Number(opacityDampeningDelay)"

              v-bind:animation-enabled="animationEnabled"
              v-bind:master-duration="Number(masterDuration)"
              v-bind:master-easing="masterEasing"
              v-bind:move-easing="moveEasing"
              v-bind:side-switch-stagger="Number(sideSwitchStagger)"
    ></Exporter>
  </div>
</template>

<script>
import Visualizer from "@/components/Visualizer";
import VueCustomScrollbar from 'vue-custom-scrollbar'
import VueSlider from 'vue-slider-component';

import Color from 'color';
import gsap from 'gsap';
import Dropzone from 'dropzone';

import * as helpers from './modules/Helpers.js';
import Exporter from "@/components/Exporter";

gsap.defaults({ overwrite: 'auto' });


export default {
  name: 'App',
  components: { Exporter, Visualizer, VueCustomScrollbar, VueSlider },

  data() {
    return {
      baseServiceUrl: 'https://not-available.vaersaagod.no/',

      exporterEnabled: false,
      matchMeta: null,
      boardSize: 520,
      boardDpi: 1,
      boardDef: {},
      boardColors: ['#191419', '#191419'],
      pgnData: '',
      allMoves: [],
      selectedMoves: [],
      movesRange: [0, 0],
      movesRangeMax: 0,

      whiteBaseColor: '#CEB850', // rgb(206,184,80)   rgb(0,140,132) rgb(204,94,0) rgb(179,0,0)
      blackBaseColor: '#12A185', // rgb(89,0,89) 
      opacityDampening: 0.007, // 0.007
      fromOpacity: 0, // 0.1
      toOpacity: 1.0, // 1.0
      minOpacity: 0, // 0
      opacityDampeningDelay: 0,

      animationEnabled: true,
      masterDuration: 10,
      masterEasingFunction: 'none', // Sine.easeInOut
      masterEasingType: 'out', // Sine.easeInOut
      moveEasingFunction: 'none',
      moveEasingType: 'out',

      sideSwitchStagger: 0,

      selectedPgnIndex: 0,
      pgns: [
        {
          name: 'Chess Tour Finals for Kiva',
          data: `[Event "Magnus Carlsen Chess Tour Finals benefiting Kiva"]
[Site "chess24.com"]
[Date "2020.08.20"]
[Round "77"]
[White "Nakamura, Hikaru"]
[Black "Carlsen, Magnus"]
[Result "1/2-1/2"]
[Board "1"]
[WhiteTitle "GM"]
[WhiteElo "2829"]
[WhiteCountry "USA"]
[WhiteFideId "2016192"]
[WhiteEloChange "1"]
[BlackTitle "GM"]
[BlackElo "2881"]
[BlackCountry "NOR"]
[BlackFideId "1503014"]
[BlackEloChange "-1"]

1. e4 {[%clk 0:05:00]} e5 {[%clk 0:04:00]} 2. Nf3 {[%clk 0:04:58]} Nc6 {[%clk
0:03:45]} 3. Bb5 {[%clk 0:04:57]} Nf6 {[%clk 0:03:45]} 4. d3 {[%clk 0:04:57]} Bc5
{[%clk 0:03:43]} 5. Bxc6 {[%clk 0:04:55]} dxc6 {[%clk 0:03:43]} 6. Nbd2 {[%clk
0:04:54]} Be6 {[%clk 0:03:43]} 7. h3 {[%clk 0:04:53]} Nd7 {[%clk 0:03:42]} 8. Nf1
{[%clk 0:04:52]} f6 {[%clk 0:03:40]} 9. g4 {[%clk 0:04:51]} Qe7 {[%clk 0:03:40]}
10. Ng3 {[%clk 0:04:50]} O-O-O {[%clk 0:03:39]} 11. Qe2 {[%clk 0:04:50]} Kb8
{[%clk 0:03:38]} 12. a3 {[%clk 0:04:42]} Bb6 {[%clk 0:03:35]} 13. Be3 {[%clk
0:04:40]} Nf8 {[%clk 0:03:25]} 14. O-O-O {[%clk 0:04:34]} Ng6 {[%clk 0:03:19]}
15. Nh5 {[%clk 0:04:30]} Bxe3+ {[%clk 0:03:16]} 16. Qxe3 {[%clk 0:04:27]} c5
{[%clk 0:03:14]} 17. Nd2 {[%clk 0:04:21]} b6 {[%clk 0:03:13]} 18. Nb1 {[%clk
0:04:16]} Nh4 {[%clk 0:02:56]} 19. Rhg1 {[%clk 0:03:49]} g5 {[%clk 0:02:55]} 20.
Nc3 {[%clk 0:03:46]} Ng6 {[%clk 0:02:54]} 21. Kb1 {[%clk 0:03:45]} Nf4 {[%clk
0:02:51]} 22. Nxf4 {[%clk 0:03:42]} gxf4 {[%clk 0:02:51]} 23. Qe2 {[%clk
0:03:39]} h5 {[%clk 0:02:48]} 24. f3 {[%clk 0:03:38]} hxg4 {[%clk 0:02:47]} 25.
hxg4 {[%clk 0:03:38]} Rh3 {[%clk 0:02:46]} 26. Rg2 {[%clk 0:03:33]} Rdh8 {[%clk
0:02:45]} 27. Rdg1 {[%clk 0:03:32]} Rh1 {[%clk 0:02:38]} 28. b3 {[%clk 0:03:28]}
c4 {[%clk 0:02:37]} 29. dxc4 {[%clk 0:03:23]} Qxa3 {[%clk 0:02:36]} 30. g5 {[%clk
0:03:05]} fxg5 {[%clk 0:02:34]} 31. Rxg5 {[%clk 0:03:04]} a5 {[%clk 0:02:20]} 32.
Qd3 {[%clk 0:02:48]} Kb7 {[%clk 0:02:15]} 33. Rg7 {[%clk 0:02:43]} Rxg1+ {[%clk
0:02:06]} 34. Rxg1 {[%clk 0:02:43]} Rh7 {[%clk 0:02:00]} 35. Na4 {[%clk 0:02:29]}
Qd6 {[%clk 0:01:45]} 36. Qc3 {[%clk 0:02:15]} Bd7 {[%clk 0:01:31]} 37. Nb2 {[%clk
0:02:13]} Rh3 {[%clk 0:01:26]} 38. Rd1 {[%clk 0:02:02]} Qe7 {[%clk 0:01:26]} 39.
Nd3 {[%clk 0:01:57]} Rxf3 {[%clk 0:01:24]} 40. Qxe5 {[%clk 0:01:56]} Rxd3 {[%clk
0:01:22]} 41. Qxe7 {[%clk 0:01:55]} Rxd1+ {[%clk 0:01:21]} 42. Kb2 {[%clk
0:01:54]} Bc6 {[%clk 0:01:21]} 43. e5 {[%clk 0:01:46]} f3 {[%clk 0:01:20]} 44.
Qf6 {[%clk 0:01:44]} Rd2 {[%clk 0:01:16]} 45. e6 {[%clk 0:01:41]} f2 {[%clk
0:01:11]} 46. e7 {[%clk 0:01:40]} Re2 {[%clk 0:01:10]} 47. Qf7 {[%clk 0:01:33]}
Rxe7 {[%clk 0:01:08]} 48. Qxf2 {[%clk 0:01:32]} Rg7 {[%clk 0:01:08]} 49. Qf4
{[%clk 0:01:31]} Rg2 {[%clk 0:01:08]} 50. Qe5 {[%clk 0:01:30]} Rg6 {[%clk
0:01:06]} 51. c3 {[%clk 0:01:29]} Rg2+ {[%clk 0:01:04]} 52. Kc1 {[%clk 0:01:28]}
Rg4 {[%clk 0:01:03]} 53. Qe6 {[%clk 0:01:21]} Re4 {[%clk 0:01:02]} 54. Qf7 {[%clk
0:01:21]} Re1+ {[%clk 0:01:00]} 55. Kd2 {[%clk 0:01:20]} Re4 {[%clk 0:01:00]} 56.
b4 {[%clk 0:01:19]} axb4 {[%clk 0:01:00]} 57. cxb4 {[%clk 0:01:19]} b5 {[%clk
0:00:59]} 58. cxb5 {[%clk 0:01:13]} Bxb5 {[%clk 0:01:00]} 59. Qd5+ {[%clk
0:01:12]} Bc6 {[%clk 0:00:59]} 60. Qc5 {[%clk 0:01:12]} Re6 {[%clk 0:00:57]} 61.
Kc3 {[%clk 0:01:11]} Rd6 {[%clk 0:00:56]} 62. b5 {[%clk 0:01:10]} Bd7 {[%clk
0:00:56]} 63. Qe5 {[%clk 0:01:09]} Bc8 {[%clk 0:00:55]} 64. Kc4 {[%clk 0:01:08]}
Rb6 {[%clk 0:00:55]} 65. Kc5 {[%clk 0:01:05]} Kb8 {[%clk 0:00:55]} 66. Qe8 {[%clk
0:01:04]} Rd6 {[%clk 0:00:55]} 67. Qf8 {[%clk 0:00:31]} Rb6 {[%clk 0:00:55]}
1/2-1/2`
        },
        {
          name: 'New York Rosenwald-03',
          data: `[Event "New York Rosenwald-03"]
[Site "New York"]
[Date "1956.10.17"]
[Round "8"]
[White "Byrne, Donald"]
[Black "Fischer, Robert James"]
[Result "0-1"]
[ECO "D97"]
[PlyCount "82"]
[EventDate "1956.10.07"]
[EventType "tourn"]
[EventRounds "11"]
[EventCountry "USA"]
[SourceTitle "100 Jahre Schach"]
[Source "ChessBase"]
[SourceDate "2000.04.19"]
[SourceVersion "1"]
[SourceVersionDate "2000.04.19"]
[SourceQuality "1"]

1. Nf3 Nf6 2. c4 g6 3. Nc3 Bg7 4. d4 O-O 5. Bf4 d5 6. Qb3 dxc4 7. Qxc4 c6 8. e4
Nbd7 9. Rd1 Nb6 10. Qc5 Bg4 11. Bg5 Na4 12. Qa3 Nxc3 13. bxc3 Nxe4 14. Bxe7 Qb6
15. Bc4 Nxc3 16. Bc5 Rfe8+ 17. Kf1 Be6 18. Bxb6 Bxc4+ 19. Kg1 Ne2+ 20. Kf1
Nxd4+ 21. Kg1 Ne2+ 22. Kf1 Nc3+ 23. Kg1 axb6 24. Qb4 Ra4 25. Qxb6 Nxd1 26. h3
Rxa2 27. Kh2 Nxf2 28. Re1 Rxe1 29. Qd8+ Bf8 30. Nxe1 Bd5 31. Nf3 Ne4 32. Qb8 b5
33. h4 h5 34. Ne5 Kg7 35. Kg1 Bc5+ 36. Kf1 Ng3+ 37. Ke1 Bb4+ 38. Kd1 Bb3+ 39.
Kc1 Ne2+ 40. Kb1 Nc3+ 41. Kc1 Rc2# 0-1`
        },
        {
          name: 'Airthings masters',
          data: `[Event "Skilling Open"]
[Site "chess24.com INT"]
[Date "2020.11.27"]
[EventDate "2020.11.25"]
[Round "2.12"]
[Result "1-0"]
[White "Wesley So"]
[Black "Hikaru Nakamura"]
[ECO "C53"]
[WhiteElo "2770"]
[BlackElo "2736"]
[PlyCount "81"]

1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d3 d6 6. O-O h6
7. Re1 O-O 8. Nbd2 a5 9. Nf1 Be6 10. Bb5 Bb6 11. Ng3 Nh7
12. h3 Ng5 13. Nxg5 hxg5 14. d4 exd4 15. Bxc6 bxc6 16. cxd4 c5
17. d5 Bd7 18. a4 Re8 19. Bd2 c4 20. Qf3 Rb8 21. Bc3 Bc5
22. Re2 f6 23. Nf5 Bb4 24. Bd4 Bc5 25. Bc3 Bb4 26. Ne3 Bc8
27. Bd4 Ba6 28. Nf5 Bc8 29. Ne3 Ba6 30. Nf5 Bc8 31. Rc1 Qd7
32. Rec2 Ba6 33. h4 gxh4 34. Qg4 Kf8 35. Bxf6 gxf6 36. Qxh4
Kg8 37. Re2 Re5 38. Re3 Rxf5 39. Rh3 Rg5 40. Qh8+ Kf7 41. Rh7+
1-0`
        },
        {
          name: 'Opera Euro Rapid',
          data: `[Event "Airthings Masters | Knockout"]
[Site "chess24.com"]
[Date "2020.12.30"]
[Round "5"]
[White "Dubov, Daniil"]
[Black "Carlsen, Magnus"]
[Result "1-0"]
[Board "1"]
[WhiteTitle "GM"]
[WhiteElo "2770"]
[WhiteCountry "RUS"]
[WhiteFideId "24126055"]
[WhiteEloChange "7"]
[BlackTitle "GM"]
[BlackElo "2881"]
[BlackCountry "NOR"]
[BlackFideId "1503014"]
[BlackEloChange "-7"]

1. d4 {[%clk 0:15:20]} Nf6 {[%clk 0:15:20]} 2. c4 {[%clk 0:15:25]} e6 {[%clk
0:15:28]} 3. Nf3 {[%clk 0:15:26]} d5 {[%clk 0:15:36]} 4. g3 {[%clk 0:15:32]} Bb4+
{[%clk 0:15:44]} 5. Nbd2 {[%clk 0:15:41]} O-O {[%clk 0:15:52]} 6. Bg2 {[%clk
0:15:48]} b6 {[%clk 0:16:01]} 7. O-O {[%clk 0:15:56]} Bb7 {[%clk 0:16:10]} 8. Ne5
{[%clk 0:16:04]} a5 {[%clk 0:16:07]} 9. Qc2 {[%clk 0:15:42]} a4 {[%clk 0:15:01]}
10. Rd1 {[%clk 0:13:44]} Bd6 {[%clk 0:12:03]} 11. cxd5 {[%clk 0:12:35]} exd5
{[%clk 0:11:41]} 12. Ndc4 {[%clk 0:12:00]} h6 {[%clk 0:11:29]} 13. Bf4 {[%clk
0:11:30]} Re8 {[%clk 0:11:00]} 14. Rac1 {[%clk 0:11:16]} Na6 {[%clk 0:10:33]} 15.
a3 {[%clk 0:10:11]} Bf8 {[%clk 0:09:37]} 16. Ne3 {[%clk 0:08:44]} c5 {[%clk
0:06:55]} 17. Nf5 {[%clk 0:07:08]} cxd4 {[%clk 0:06:09]} 18. Nc6 {[%clk 0:05:02]}
Qd7 {[%clk 0:04:06]} 19. Bh3 {[%clk 0:05:02]} Kh8 {[%clk 0:03:19]} 20. Ne5 {[%clk
0:04:54]} Rxe5 {[%clk 0:02:41]} 21. Bxe5 {[%clk 0:05:01]} Ne4 {[%clk 0:02:50]}
22. Bxd4 {[%clk 0:03:49]} Rc8 {[%clk 0:02:37]} 23. Qd3 {[%clk 0:03:16]} Nac5
{[%clk 0:02:11]} 24. Qe3 {[%clk 0:02:54]} Kh7 {[%clk 0:02:11]} 25. Bxc5 {[%clk
0:01:15]} Nxc5 {[%clk 0:02:17]} 26. Qf3 {[%clk 0:00:44]} d4 {[%clk 0:01:43]} 27.
Rxd4 {[%clk 0:00:32]} Qe8 {[%clk 0:01:26]} 28. Qe3 {[%clk 0:00:30]} Qc6 {[%clk
0:01:24]} 29. f3 {[%clk 0:00:35]} Re8 {[%clk 0:01:12]} 30. Qf2 {[%clk 0:00:42]}
g6 {[%clk 0:01:00]} 31. Ne3 {[%clk 0:00:45]} Qf6 {[%clk 0:00:43]} 32. Ng4 {[%clk
0:00:20]} Qg7 {[%clk 0:00:49]} 33. Rcd1 {[%clk 0:00:18]} h5 {[%clk 0:00:39]} 34.
Ne3 {[%clk 0:00:26]} Nb3 {[%clk 0:00:47]} 35. R4d3 {[%clk 0:00:13]} Qxb2 {[%clk
0:00:17]} 36. Rd7 {[%clk 0:00:22]} Bc5 {[%clk 0:00:23]} 37. Rxf7+ {[%clk
0:00:20]} Kh6 {[%clk 0:00:23]} 38. Rdd7 {[%clk 0:00:22]} Qa1+ {[%clk 0:00:20]}
39. Kg2 {[%clk 0:00:28]} Bxe3 {[%clk 0:00:27]} 40. Rh7+ {[%clk 0:00:21]} Kg5
{[%clk 0:00:36]} 41. Rxb7 {[%clk 0:00:30]} Rf8 {[%clk 0:00:34]} 42. Qxe3+ {[%clk
0:00:37]} 1-0`
        },
        {
          name: '75th Tata Steel GpA',
          data: `[Event "75th Tata Steel GpA"]
[Site "Wijk aan Zee NED"]
[Date "2013.01.15"]
[Round "?"]
[White "Levon Aronian"]
[Black "Viswanathan Anand"]
[Result "0-1"]

1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. e3 Nbd7 6. Bd3 dxc4 7. Bxc4 b5 8. Bd3
Bd6 9. O-O O-O 10. Qc2 Bb7 11. a3 Rc8 12. Ng5 c5 13. Nxh7 Ng4 14. f4 cxd4 15.
exd4 Bc5 16. Be2 Nde5 17. Bxg4 Bxd4+ 18. Kh1 Nxg4 19. Nxf8 f5 20. Ng6 Qf6 21. h3
Qxg6 22. Qe2 Qh5 23. Qd3 Be3 0-1`
        },
        {
          name: 'Karpov v Kasparov 1985',
          data: `[Event "Ch World (match)"]
[Site "Moscow (Russia)"]
[Date "1985.??.??"]
[Round "?"]
[White "Anatoly Karpov"]
[Black "Garry Kasparov"]
[Result "0-1"]

1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nb5 d6 6. c4 Nf6 7. N1c3 a6 8. Na3
d5 9. cxd5 exd5 10. exd5 Nb4 11. Be2 Bc5 12. O-O O-O 13. Bf3 Bf5 14. Bg5 Re8 15.
Qd2 b5 16. Rad1 Nd3 17. Nab1 h6 18. Bh4 b4 19. Na4 Bd6 20. Bg3 Rc8 21. b3 g5 22.
Bxd6 Qxd6 23. g3 Nd7 24. Bg2 Qf6 25. a3 a5 26. axb4 axb4 27. Qa2 Bg6 28. d6 g4
29. Qd2 Kg7 30. f3 Qxd6 31. fxg4 Qd4+ 32. Kh1 Nf6 33. Rf4 Ne4 34. Qxd3 Nf2+ 35.
Rxf2 Bxd3 36. Rfd2 Qe3 37. Rxd3 Rc1 38. Nb2 Qf2 39. Nd2 Rxd1+ 40. Nxd1 Re1+ 0-1`
        },
          {
          name: 'Morphy v Duke Karl/Count Isouard 1858',
          data: `[Event "Paul Morphy - Duke Karl Count Isouard (1858.??.??)"]
[Site "Paris (France)"]
[Date "1858.??.??"]
[Round "?"]
[White "Paul Morphy"]
[Black "Duke of Brunswick and Count Isouard"]
[Result "1-0"]

1. e4 e5 2. Nf3 d6 3. d4 Bg4 4. dxe5 Bxf3 5. Qxf3 dxe5 6. Bc4 Nf6 7. Qb3 Qe7 8.
Nc3 c6 9. Bg5 b5 10. Nxb5 cxb5 11. Bxb5+ Nbd7 12. O-O-O Rd8 13. Rxd7 Rxd7 14.
Rd1 Qe6 15. Bxd7+ Nxd7 16. Qb8+ Nxb8 17. Rd8# 1-0`
        },
      ],
      uploadedPgns: [],

      changeTimeout: 0,
      easingMethods: [
        'none',
        'power1',
        'power2',
        'power3',
        'power4',
        'sine',
        'circ',
        'quint',
        'expo',
      ],
      easingTypes: [
        'out',
        'inOut',
        'in',
      ]
    };
  },

  watch: {
    selectedPgnIndex() {
      this.updateMatchData();
    }
  },

  computed: {
    masterEasing() {
      if (this.masterEasingFunction === 'none') {
        return 'none'
      }

      return this.masterEasingFunction + '.' + this.masterEasingType
    },
    moveEasing() {
      if (this.moveEasingFunction === 'none') {
        return 'none'
      }

      return this.moveEasingFunction + '.' + this.moveEasingType
    },
    matchPlayers() {
      if (this.matchMeta === null) {
        return ''
      }

      return '<span class="text-20 font-bold">' + this.matchMeta.White + '</span> <span class="text-main-medium">vs</span> <span class="text-20 font-bold">' + this.matchMeta.Black + '</span>';
    },
    matchEvent() {
      if (this.matchMeta === null) {
        return ''
      }

      return this.matchMeta.Event;
    }
  },

  methods: {
    updateMatchData() {
      const pgnData = this.pgns[this.selectedPgnIndex].data;
      this.pgnData = pgnData;
      this.matchMeta = helpers.getMeta(pgnData);
      this.allMoves = helpers.parseMoves(pgnData, this.boardDef);
      this.movesRangeMax = this.allMoves.length - 1;
      this.movesRange = [0, 0];

      this.$nextTick(() => {
        this.movesRange = [0, this.allMoves.length - 1];
        this.updateSelectedMoves();
      });
    },
    updateSelectedMoves() {
      this.selectedMoves = this.allMoves.slice(this.movesRange[0], this.movesRange[1]);
    },
    onSelectedMovesChange() {
      clearTimeout(this.changeTimeout);

      this.changeTimeout = setTimeout(() => {
        this.updateSelectedMoves();
      }, 100)

    },
    getColorFromEvent(event) {
      return Color(window.getComputedStyle(event.target).getPropertyValue('background-color')).hex();
    },

    onExport() {
      this.exporterEnabled = true;
    },

    onExportClose() {
      this.exporterEnabled = false;
    },

    initDropzone() {
      let myDropzone = new Dropzone("#fileUploadWrapper", {
        url: this.baseServiceUrl + 'api/upload-pgn',
        acceptedFiles: '.pgn',
        previewTemplate: '<span></span>',
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
        },
        success: (dz, response) => {
          if (response.success) {
            this.pgns.push({
              name: response.filename,
              data: response.data,
            });
            this.selectedPgnIndex = this.pgns.length - 1;
          } else {
            alert('An error occurred when uploading file.');
          }
        },
        error: (dz, errorMessage, xhr) => {
          alert(errorMessage);
        }
      });

      myDropzone.disable();
    }
  },

  mounted() {
    this.initDropzone();
    this.boardDef = helpers.getBoardDef(this.boardSize);
    this.updateMatchData();
  },

  unmounted() {

  }

};
</script>
