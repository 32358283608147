import * as helpers from '@/modules/Helpers';
import Chess from 'chess.js';
import ShapeKnight from "@/classes/ShapeKnight";
import ShapeDiagonal from "@/classes/ShapeDiagonal";
import ShapeBar from "@/classes/ShapeBar";

export default class Move {
    constructor(boardDef, move) {
        this.boardDef = boardDef;
        this.rawMove = move;
        
        this.color = move.color;
        this.piece = move.piece;
        this.from = move.from;
        this.to = move.to;
        this.flags = move.flags;
        this.san = move.san;

        this.init();
    }

    init() {
        this.steps = this.getSteps();
        this.moveLength = this.steps.length;
        this.shapeType = this.getShapeType(this.steps, this.piece);
        this.direction = this.getDirection(this.steps, this.piece)
        
        if (this.shapeType === helpers.SHAPE_KNIGHT) {
            this.shape = new ShapeKnight(this.steps, this.direction);
        } else if (this.shapeType === helpers.SHAPE_DIAGONAL) {
            this.shape = new ShapeDiagonal(this.steps, this.direction);
        } else {
            this.shape = new ShapeBar(this.steps, this.direction);
        }
    }

    destroy() {

    }

    getSteps() {
        const from = this.from;
        const to = this.to;
        const piece = this.piece;

        const chess = new Chess();
        let fromBlock = this.boardDef[from];
        let toBlock = this.boardDef[to];

        let steps = [fromBlock];

        const deltaX = fromBlock.indexX - toBlock.indexX;
        const deltaY = fromBlock.indexY - toBlock.indexY;

        let currentX = fromBlock.indexX;
        let currentY = fromBlock.indexY;

        // For the knight, we need to move just in one direction at a time.
        // We switch direction based on which direction is the prominent. Might need to be ammended.
        if (piece === chess.KNIGHT) {
            if (Math.abs(deltaX) < Math.abs(deltaY)) {
                do {
                    if (deltaY !== 0) {
                        currentY -= (deltaY / Math.abs(deltaY));
                    }

                    const key = helpers.boardCols[currentX] + helpers.boardRows[currentY];
                    steps.push(this.boardDef[key]);
                } while (currentY !== toBlock.indexY);

                do {
                    if (deltaX !== 0) {
                        currentX -= (deltaX / Math.abs(deltaX));
                    }

                    const key = helpers.boardCols[currentX] + helpers.boardRows[currentY];
                    steps.push(this.boardDef[key]);
                } while (currentX !== toBlock.indexX);
            } else {
                do {
                    if (deltaX !== 0) {
                        currentX -= (deltaX / Math.abs(deltaX));
                    }

                    const key = helpers.boardCols[currentX] + helpers.boardRows[currentY];
                    steps.push(this.boardDef[key]);
                } while (currentX !== toBlock.indexX);

                do {
                    if (deltaY !== 0) {
                        currentY -= (deltaY / Math.abs(deltaY));
                    }

                    const key = helpers.boardCols[currentX] + helpers.boardRows[currentY];
                    steps.push(this.boardDef[key]);
                } while (currentY !== toBlock.indexY);

            }
        } else {
            let currentX = fromBlock.indexX;
            let currentY = fromBlock.indexY;

            do {
                if (deltaX !== 0) {
                    currentX -= (deltaX / Math.abs(deltaX));
                }
                if (deltaY !== 0) {
                    currentY -= (deltaY / Math.abs(deltaY));
                }

                const key = helpers.boardCols[currentX] + helpers.boardRows[currentY];
                steps.push(this.boardDef[key]);
            } while (currentX !== toBlock.indexX && currentY !== toBlock.indexY);

            steps.push(toBlock);
        }

        return steps;
    };

    getDirection(steps) {
        const deltaX = steps[0].indexX - steps[steps.length - 1].indexX;
        const deltaY = steps[0].indexY - steps[steps.length - 1].indexY;

        if (deltaX === 0) {
            if (deltaY > 0) {
                return 'up'
            }
            if (deltaY < 0) {
                return 'down'
            }
            return '';
        }

        if (deltaY === 0) {
            if (deltaX > 0) {
                return 'left'
            }
            if (deltaX < 0) {
                return 'right'
            }
            return '';
        }

        return [
            deltaX > 0 ? 'left' : (deltaX < 0 ? 'right' : ''),
            deltaY > 0 ? 'up' : (deltaY < 0 ? 'down' : '')
        ].join('-');
    };

    getShapeType(steps, piece) {
        const chess = new Chess();
        
        if (piece === chess.KNIGHT) {
            return helpers.SHAPE_KNIGHT;
        }

        const deltaX = steps[0].indexX - steps[steps.length - 1].indexX;
        const deltaY = steps[0].indexY - steps[steps.length - 1].indexY;

        if (deltaX !== 0 && deltaY !== 0) {
            return helpers.SHAPE_DIAGONAL;
        }

        return helpers.SHAPE_BAR;
    };

}
