import gsap from 'gsap';

export default class ShapeBar {

    constructor(steps, direction) {
        this.steps = steps;
        this.direction = direction;
        
        this.init();
    }

    init() {
        this.getPoints();
    }
    
    getPoints() {
        const steps = this.steps;
        
        if (this.direction === 'up') {
            this.fromPoints = [
                { x: steps[0].startX, y: steps[0].endY },
                { x: steps[0].endX, y: steps[0].endY }
            ];
            this.toPoints = [
                { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].startY },
                { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].startY }
            ];
        }

        if (this.direction === 'down') {
            this.fromPoints = [
                { x: steps[0].startX, y: steps[0].startY },
                { x: steps[0].endX, y: steps[0].startY }
            ];
            this.toPoints = [
                { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].endY },
                { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].endY }
            ];

        }

        if (this.direction === 'left') {
            this.fromPoints = [
                { x: steps[0].endX, y: steps[0].startY },
                { x: steps[0].endX, y: steps[0].endY }
            ];
            this.toPoints = [
                { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].startY },
                { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].endY }
            ];
        }

        if (this.direction === 'right') {
            this.fromPoints = [
                { x: steps[0].startX, y: steps[0].startY },
                { x: steps[0].startX, y: steps[0].endY }
            ];
            this.toPoints = [
                { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].startY },
                { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].endY }
            ];
        }
    }

    getTween() {
        this.currentToPoints = JSON.parse(JSON.stringify(this.fromPoints));
        
        return gsap.timeline({ repeat: 0 })
            .to(this.currentToPoints[0], { duration: this.steps.length, x: this.toPoints[0].x, y: this.toPoints[0].y }, 0)
            .to(this.currentToPoints[1], { duration: this.steps.length, x: this.toPoints[1].x, y: this.toPoints[1].y }, 0)
            .pause();
    }

    draw(ctx, fromColor, toColor) {
        const gradient = ctx.createLinearGradient(this.fromPoints[0].x, this.fromPoints[0].y, this.currentToPoints[0].x, this.currentToPoints[0].y);
        gradient.addColorStop(0.0, fromColor);
        gradient.addColorStop(0.95, toColor);
        ctx.fillStyle = gradient;
        ctx.beginPath();
        
        ctx.moveTo(this.fromPoints[0].x, this.fromPoints[0].y);
        ctx.lineTo(this.fromPoints[1].x, this.fromPoints[1].y);
        ctx.lineTo(this.currentToPoints[1].x, this.currentToPoints[1].y);
        ctx.lineTo(this.currentToPoints[0].x, this.currentToPoints[0].y);
        
        ctx.fill();
        ctx.closePath();
    }
    
    destroy() {

    }

}
