import gsap from 'gsap';

export default class ShapeDiagonal {
    
    constructor(steps, direction) {
        this.steps = steps;
        this.direction = direction;
        
        this.init();
    }

    init() {
        this.getPoints();
    }
    
    getPoints() {
        const steps = this.steps;
        
        if (this.direction === 'right-down') {
            this.startFromPoints = [
                { x: steps[0].startX, y: steps[0].startY },
                { x: steps[0].startX, y: steps[0].startY },
                { x: steps[0].startX, y: steps[0].startY }
            ];
            this.endFromPoints = [
                { x: steps[0].endX, y: steps[0].startY },
                { x: steps[0].endX, y: steps[0].endY },
                { x: steps[0].startX, y: steps[0].endY }
            ];
            this.fromPoints = [
                { x: steps[0].endX, y: steps[0].startY },
                { x: steps[0].startX, y: steps[0].startY },
                { x: steps[0].startX, y: steps[0].endY }
            ];
            this.toPoints = [
                { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].startY },
                { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].endY },
                { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].endY }
            ];
        } else if (this.direction === 'left-down') {
            this.startFromPoints = [
                { x: steps[0].endX, y: steps[0].startY },
                { x: steps[0].endX, y: steps[0].startY },
                { x: steps[0].endX, y: steps[0].startY }
            ];
            this.endFromPoints = [
                { x: steps[0].endX, y: steps[0].endY },
                { x: steps[0].startX, y: steps[0].endY },
                { x: steps[0].startX, y: steps[0].startY }
            ];
            this.fromPoints = [
                { x: steps[0].endX, y: steps[0].endY },
                { x: steps[0].endX, y: steps[0].startY },
                { x: steps[0].startX, y: steps[0].startY }
            ];
            this.toPoints = [
                { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].endY },
                { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].endY },
                { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].startY }
            ];
        } else if (this.direction === 'right-up') {
            this.startFromPoints = [
                { x: steps[0].startX, y: steps[0].endY },
                { x: steps[0].startX, y: steps[0].endY },
                { x: steps[0].startX, y: steps[0].endY }
            ];
            this.endFromPoints = [
                { x: steps[0].startX, y: steps[0].startY },
                { x: steps[0].endX, y: steps[0].startY },
                { x: steps[0].endX, y: steps[0].endY }
            ];
            this.fromPoints = [
                { x: steps[0].startX, y: steps[0].startY },
                { x: steps[0].startX, y: steps[0].endY },
                { x: steps[0].endX, y: steps[0].endY }
            ];
            this.toPoints = [
                { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].startY },
                { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].startY },
                { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].endY }
            ];
        } else if (this.direction === 'left-up') {
            this.startFromPoints = [
                { x: steps[0].endX, y: steps[0].endY },
                { x: steps[0].endX, y: steps[0].endY },
                { x: steps[0].endX, y: steps[0].endY }
            ];
            this.endFromPoints = [
                { x: steps[0].startX, y: steps[0].endY },
                { x: steps[0].startX, y: steps[0].startY },
                { x: steps[0].endX, y: steps[0].startY }
            ];
            this.fromPoints = [
                { x: steps[0].startX, y: steps[0].endY },
                { x: steps[0].endX, y: steps[0].endY },
                { x: steps[0].endX, y: steps[0].startY }
            ];
            this.toPoints = [
                { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].endY },
                { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].startY },
                { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].startY }
            ];
        }
    }

    getTween() {
        this.currentFromPoints = JSON.parse(JSON.stringify(this.startFromPoints));
        this.currentToPoints = JSON.parse(JSON.stringify(this.startFromPoints));
        
        return gsap.timeline({ repeat: 0 })
            .to(this.currentToPoints[0], { duration: 1, x: this.endFromPoints[0].x, y: this.endFromPoints[0].y, ease: 'none' }, 0)
            .to(this.currentToPoints[1], { duration: 1, x: this.endFromPoints[1].x, y: this.endFromPoints[1].y, ease: 'none' }, 0)
            .to(this.currentToPoints[2], { duration: 1, x: this.endFromPoints[2].x, y: this.endFromPoints[2].y, ease: 'none' }, 0)
            .add(() => { 
                this.currentFromPoints = JSON.parse(JSON.stringify(this.fromPoints)); 
            })
            .to(this.currentToPoints[0], { duration: this.steps.length-1, x: this.toPoints[0].x, y: this.toPoints[0].y, ease: 'none' }, 1)
            .to(this.currentToPoints[1], { duration: this.steps.length-1, x: this.toPoints[1].x, y: this.toPoints[1].y, ease: 'none' }, 1)
            .to(this.currentToPoints[2], { duration: this.steps.length-1, x: this.toPoints[2].x, y: this.toPoints[2].y, ease: 'none' }, 1)
            .pause();
    }

    draw(ctx, fromColor, toColor) {
        const gradient = ctx.createLinearGradient(this.fromPoints[1].x, this.fromPoints[1].y, this.currentToPoints[1].x, this.currentToPoints[1].y);
        gradient.addColorStop(0.0, fromColor);
        gradient.addColorStop(0.8, toColor);
        ctx.fillStyle = gradient;
        ctx.beginPath();
        
        ctx.moveTo(this.currentFromPoints[0].x, this.currentFromPoints[0].y);
        ctx.lineTo(this.currentFromPoints[1].x, this.currentFromPoints[1].y);
        ctx.lineTo(this.currentFromPoints[2].x, this.currentFromPoints[2].y);
        
        ctx.lineTo(this.currentToPoints[2].x, this.currentToPoints[2].y);
        ctx.lineTo(this.currentToPoints[1].x, this.currentToPoints[1].y);
        ctx.lineTo(this.currentToPoints[0].x, this.currentToPoints[0].y);
        
        ctx.fill();
        ctx.closePath();
    }
    
    destroy() {

    }

}
