import Chess from 'chess.js';
import Move from "@/classes/Move";

export const boardCols = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];
export const boardRows = ['8', '7', '6', '5', '4', '3', '2', '1'];

export const SHAPE_KNIGHT = 'shapeKnight';
export const SHAPE_DIAGONAL = 'shapeDiagonal';
export const SHAPE_BAR = 'shapeBAR';

export const WHITE = 'w';
export const BLACK = 'b';

export const getBoardDef = (boardSize) => {
    let def = [];
    const blockSize = boardSize / 8;

    boardCols.forEach((x, countX) => {
        boardRows.forEach((y, countY) => {
            const startX = boardSize * (countX / 8);
            const startY = boardSize * (countY / 8);

            def[x + y] = {
                key: x + y,
                indexX: countX,
                indexY: countY,
                startX: startX,
                startY: startY,
                centerX: startX + blockSize / 2,
                centerY: startY + blockSize / 2,
                endX: startX + blockSize,
                endY: startY + blockSize,
                color: (countX + countY) % 2 ? 'dark' : 'light'
            };
        });
    });

    return def;
};

export const parseMoves = (pgnData, boardDef) => {
    const chess = new Chess();
    chess.load_pgn(pgnData);
    
    const rawMoves = chess.history({ verbose: true });
    
    let m = [];

    rawMoves.forEach(move => {
        m.push(new Move(boardDef, move));
        
        if (move.san === 'O-O-O') {
            let extraMove = { ...move };
            
            if (move.color === BLACK) {
                if (move.flags === 'q') {
                    extraMove.piece = chess.ROOK;
                    extraMove.from = 'a8';
                    extraMove.to = 'd8';
                } else {
                    extraMove.piece = chess.ROOK;
                    extraMove.from = 'h8';
                    extraMove.to = 'e8';
                }
            } else {
                if (move.flags === 'q') {
                    extraMove.piece = chess.ROOK;
                    extraMove.from = 'a1';
                    extraMove.to = 'd1';
                } else {
                    extraMove.piece = chess.ROOK;
                    extraMove.from = 'h1';
                    extraMove.to = 'e1';
                }
            }
            
            m.push(new Move(boardDef, extraMove));
        }
    });
    
    return m;
};

export const getMeta = pgnData => {
    const chess = new Chess();
    chess.load_pgn(pgnData);
    return chess.header();
};

