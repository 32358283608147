import gsap from 'gsap';

export default class ShapeKnight {
    
    constructor(steps, direction) {
        this.steps = steps;
        this.direction = direction;
        
        this.init();
    }

    init() {
        this.getPoints();
    }
    
    getPoints() {
        const steps = this.steps;
        
        if (this.direction === 'right-down') {
            this.isTall = (steps[steps.length - 1].endY - steps[0].startY) > (steps[steps.length - 1].endX - steps[0].startX);
            
            if (this.isTall) {
                this.fromPoints1 = [
                    { x: steps[0].startX, y: steps[0].startY },
                    { x: steps[0].endX, y: steps[0].startY }
                ];
                this.toPoints1 = [
                    { x: steps[steps.length - 2].startX, y: steps[steps.length - 2].endY },
                    { x: steps[steps.length - 2].endX, y: steps[steps.length - 2].endY }
                ];
                this.fromPoints2 = [
                    { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].startY },
                    { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].endY }
                ];
                this.toPoints2 = [
                    { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].startY },
                    { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].endY }
                ];
            } else {
                this.fromPoints1 = [
                    { x: steps[0].startX, y: steps[0].startY },
                    { x: steps[0].startX, y: steps[0].endY }
                ];
                this.toPoints1 = [
                    { x: steps[steps.length - 2].endX, y: steps[steps.length - 2].startY },
                    { x: steps[steps.length - 2].endX, y: steps[steps.length - 2].endY }
                ];
                this.fromPoints2 = [
                    { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].startY },
                    { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].startY }
                ];
                this.toPoints2 = [
                    { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].endY },
                    { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].endY }
                ];
            }
            
            this.gradientStart = { x: steps[0].startX, y: steps[0].startY };
            this.gradientEnd = { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].endY };
            
        } else if (this.direction === 'left-down') {
            
            this.isTall = (steps[steps.length - 1].endY - steps[0].startY) > (steps[0].endX - steps[steps.length - 1].startX);

            if (this.isTall) {
                this.fromPoints1 = [
                    { x: steps[0].startX, y: steps[0].startY },
                    { x: steps[0].endX, y: steps[0].startY }
                ];
                this.toPoints1 = [
                    { x: steps[steps.length - 2].startX, y: steps[steps.length - 2].endY },
                    { x: steps[steps.length - 2].endX, y: steps[steps.length - 2].endY }
                ];
                this.fromPoints2 = [
                    { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].startY },
                    { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].endY }
                ];
                this.toPoints2 = [
                    { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].startY },
                    { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].endY }
                ];
            } else {
                this.fromPoints1 = [
                    { x: steps[0].endX, y: steps[0].startY },
                    { x: steps[0].endX, y: steps[0].endY }
                ];
                this.toPoints1 = [
                    { x: steps[steps.length - 2].startX, y: steps[steps.length - 2].startY },
                    { x: steps[steps.length - 2].startX, y: steps[steps.length - 2].endY }
                ];
                this.fromPoints2 = [
                    { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].startY },
                    { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].startY }
                ];
                this.toPoints2 = [
                    { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].endY },
                    { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].endY }
                ];
            }
                
            
            this.gradientStart = { x: steps[0].endX, y: steps[0].startY };
            this.gradientEnd = { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].endY };
            
            
        } else if (this.direction === 'right-up') {
            this.isTall = (steps[0].endY - steps[steps.length - 1].startY) > (steps[steps.length - 1].endX - steps[0].startX);
            
            if (this.isTall) {
                this.fromPoints1 = [
                    { x: steps[0].startX, y: steps[0].endY },
                    { x: steps[0].endX, y: steps[0].endY }
                ];
                this.toPoints1 = [
                    { x: steps[steps.length - 2].startX, y: steps[steps.length - 2].startY },
                    { x: steps[steps.length - 2].endX, y: steps[steps.length - 2].startY }
                ];
                this.fromPoints2 = [
                    { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].startY },
                    { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].endY }
                ];
                this.toPoints2 = [
                    { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].startY },
                    { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].endY }
                ];
            } else {
                this.fromPoints1 = [
                    { x: steps[0].startX, y: steps[0].startY },
                    { x: steps[0].startX, y: steps[0].endY }
                ];
                this.toPoints1 = [
                    { x: steps[steps.length - 2].endX, y: steps[steps.length - 2].startY },
                    { x: steps[steps.length - 2].endX, y: steps[steps.length - 2].endY }
                ];
                this.fromPoints2 = [
                    { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].endY },
                    { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].endY }
                ];
                this.toPoints2 = [
                    { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].startY },
                    { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].startY }
                ];
            }
            
            this.gradientStart = { x: steps[0].startX, y: steps[0].endY };
            this.gradientEnd = { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].startY };
        
        } else if (this.direction === 'left-up') {
            this.isTall = (steps[0].endY - steps[steps.length - 1].startY) > (steps[0].endX - steps[steps.length - 1].startX);

            if (this.isTall) {
                this.fromPoints1 = [
                    { x: steps[0].startX, y: steps[0].endY },
                    { x: steps[0].endX, y: steps[0].endY }
                ];
                this.toPoints1 = [
                    { x: steps[steps.length - 2].startX, y: steps[steps.length - 2].startY },
                    { x: steps[steps.length - 2].endX, y: steps[steps.length - 2].startY }
                ];
                this.fromPoints2 = [
                    { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].endY },
                    { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].startY }
                ];
                this.toPoints2 = [
                    { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].endY },
                    { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].startY }
                ];
            } else {
                this.fromPoints1 = [
                    { x: steps[0].endX, y: steps[0].startY },
                    { x: steps[0].endX, y: steps[0].endY }
                ];
                this.toPoints1 = [
                    { x: steps[steps.length - 2].startX, y: steps[steps.length - 2].startY },
                    { x: steps[steps.length - 2].startX, y: steps[steps.length - 2].endY }
                ];
                this.fromPoints2 = [
                    { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].endY },
                    { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].endY }
                ];
                this.toPoints2 = [
                    { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].startY },
                    { x: steps[steps.length - 1].endX, y: steps[steps.length - 1].startY }
                ];
            }
            
            this.gradientStart = { x: steps[0].endX, y: steps[0].endY };
            this.gradientEnd = { x: steps[steps.length - 1].startX, y: steps[steps.length - 1].startY };
        }
    }
    
    getTween() {
        this.currentToPoints1 = JSON.parse(JSON.stringify(this.fromPoints1));
        this.currentToPoints2 = JSON.parse(JSON.stringify(this.fromPoints2));
        this.currentToGradient = JSON.parse(JSON.stringify(this.gradientStart));
        
        return gsap.timeline({ repeat: 0 })
            .to(this.currentToGradient, { duration: this.steps.length, x: this.gradientEnd.x, y: this.gradientEnd.y, ease: 'none' }, 0)
            .to(this.currentToPoints1[0], { duration: this.steps.length - 1, x: this.toPoints1[0].x, y: this.toPoints1[0].y, ease: 'none' }, 0)
            .to(this.currentToPoints1[1], { duration: this.steps.length - 1, x: this.toPoints1[1].x, y: this.toPoints1[1].y, ease: 'none' }, 0)
            .to(this.currentToPoints2[0], { duration: 1, x: this.toPoints2[0].x, y: this.toPoints2[0].y, ease: 'none' }, this.steps.length - 1)
            .to(this.currentToPoints2[1], { duration: 1, x: this.toPoints2[1].x, y: this.toPoints2[1].y, ease: 'none' }, this.steps.length - 1)
            .pause();

    }

    draw(ctx, fromColor, toColor) {
        const gradient = ctx.createLinearGradient(this.gradientStart.x, this.gradientStart.y, this.currentToGradient.x, this.currentToGradient.y);
        gradient.addColorStop(0.0, fromColor);
        gradient.addColorStop(1.0, toColor);
        ctx.fillStyle = gradient;
        ctx.beginPath();
        
        ctx.moveTo(this.fromPoints1[0].x, this.fromPoints1[0].y);
        ctx.lineTo(this.fromPoints1[1].x, this.fromPoints1[1].y);
        ctx.lineTo(this.currentToPoints1[1].x, this.currentToPoints1[1].y);
        ctx.lineTo(this.currentToPoints1[0].x, this.currentToPoints1[0].y);
        
        ctx.fill();
        ctx.closePath();
        ctx.beginPath();
        
        ctx.moveTo(this.fromPoints2[0].x, this.fromPoints2[0].y);
        ctx.lineTo(this.fromPoints2[1].x, this.fromPoints2[1].y);
        ctx.lineTo(this.currentToPoints2[1].x, this.currentToPoints2[1].y);
        ctx.lineTo(this.currentToPoints2[0].x, this.currentToPoints2[0].y);
        
        ctx.fill();
        ctx.closePath();
    }
    
    destroy() {

    }

}
